import hookIntoXhr from "javascript/xhrHook";
import Sentry from "javascript/Sentry/Wrapper";

Sentry.init();
window.XhrSentryInstance = Sentry;

const transaction = Sentry.startTransaction({ name: "app-load" });

const loadingLocalStorageKey = "espark:loading";
setTimeout(() => {
  // if the app doesn't load, show a graphic and try again
  if (!document.querySelector(".main")) {
    const currentValue = parseInt(localStorage.getItem(loadingLocalStorageKey)) || 0;
    if (currentValue < 4) {
      localStorage.setItem(loadingLocalStorageKey, currentValue + 1);
      window.location.reload(false);
    } else {
      localStorage.removeItem(loadingLocalStorageKey);
      Sentry.captureMessage("Failed to load the eSpark app due to timeouts");
      alert("Oops! eSpark is having a problem loading. Please ask your teacher for help.");
    }
  } else {
    localStorage.removeItem(loadingLocalStorageKey);
  }
}, 5000);

// import LearnosityLibrary from "javascript/Learnosity/LearnosityLibrary";
import setupShowLearnosityAssesesmentV2 from "javascript/Learnosity/showLearnosityAssessment";
import setupMonitoringPorts from "javascript/Monitor";
import { getPlatformData, logPlatformData } from "javascript/PlatformData.js";
import { setupAuthPorts } from "javascript/UserAuth.js";
import setupComponentPorts from "javascript/Components/showComponent";
import setupNotificationPorts from "javascript/Notifications.js";
import setupVideoPlayerPorts from "javascript/VideoPlayerPorts.js";
import { setupEsparkTTSPorts } from "javascript/Audio/esparkTextToSpeech";
import setupVideoRecordingPorts from "javascript/VideoRecording/ElmCommunication.js";
import setupFaviconPorts from "javascript/Favicon.js";
import setupWindowManagementPorts from "javascript/WindowManagement.js";
import setupScrollingPorts from "javascript/ScrollingPorts.js";
import setupDeviceCheckPorts from "javascript/DeviceCheck.js";
import setupHotjarPorts from "javascript/Hotjar/Hotjar.js";
import setupGameActivityBrowserHealthPorts from "javascript/GameActivityBrowserHealth.js";
import setupSoundPorts from "./Audio/Sound";
import * as LocalStorage from "javascript/LocalStorage.js";
import Logger from "javascript/Logger.js";
import AppTimerStorage from "javascript/AppTimerStorage.js";
import setupIos from "javascript/IosSetup.js";
import "css/index.scss";
import "css/postcss/FixedItemGrid.pcss";
import setupQRCodeReaderPorts from "javascript/QRCodeReaderPorts.js";
import setupMediaCapturePermissionsPorts from "javascript/MediaCapturePermissions.js";
import setupTeacherTourIframeUpdatePorts from "javascript/TeacherTour/IframeCommunication";
import setupPLEPorts from "javascript/PLE/IframeCommunication";
import App from "elm/Main.elm";
import { stopDoubleTapZoom } from "javascript/StopDoubleTapBehaviour.js";
import setupEbookReader from "javascript/Ebooks/EbookActivity";
import setupStudentActivityMessageListenerPorts from "javascript/MessageListener/StudentActivityMessageListener";
import setupHighfivePorts from "javascript/Highfive.js";
import setupChoiceTextPorts from "./ChoiceText/IframeCommunication";
import { setupHeartbeatPorts } from "javascript/Heartbeat/heartbeatPorts";
// Elm takes over the entire DOM, so we have to preserve certain elements ourselves.
const elementsToPreserve = [document.getElementById("svgs")];
elementsToPreserve.forEach(element => {
  element.remove();
});

const platformData = getPlatformData();
let app;
try {
  app = App.Elm.Main.init({
    flags: platformData
  });
} catch (e) {
  console.warn("Error initializing the Elm app", e);
  Sentry.captureMessage(e);
}

if (app) {
  const logger = new Logger();
  global.logger = logger;
  logger.startListening(app.ports, platformData.devFlags.eventLoggingFrequency);

  // let's log app initialization and platform support
  logPlatformData(platformData);

  // make sure we aren't playing audios for the elm controls text
  setTimeout(() => {
    const elmOverlay = document.querySelector(".elm-overlay");
    elmOverlay && elmOverlay.dataset && (elmOverlay.dataset.ignore = "true");
  }, 1000);

  // Make the Elm dialog manipulable -- Elm doesn't give it any identifier so we have to find it
  // ourselves
  try {
    const divs = Array.prototype.slice.apply(document.getElementsByTagName("div"));
    const elmDebugBox = divs.filter(
      div =>
        div.style.bottom === "2em" && div.style.right === "2em" && div.style.zIndex === "2147483647"
    )[0];
    if (elmDebugBox) {
      elmDebugBox.classList.add("elm-mini-controls");
      if (window.environment) {
        elmDebugBox.classList.add(window.environment);
      }
    }
  } catch (e) {
    // if we can't pull that off, don't crash
  }

  // restore the SVGs
  const restoreElements = () => {
    // if Elm has finished manipulating the DOM, this node will be gone
    if (!document.getElementById("elm-canary")) {
      elementsToPreserve.forEach(element => {
        document.body.appendChild(element);
      });
    } else {
      // the canary is still present, so wait and try again
      setTimeout(restoreElements, 100);
    }
  };
  restoreElements();

  setupAuthPorts(app.ports);
  if (!window.config.disableHeartbeats) setupHeartbeatPorts(app.ports, platformData);
  LocalStorage.setupPorts(app.ports);
  setupNotificationPorts(app.ports);

  const tts = setupEsparkTTSPorts(app.ports, window.config.aws);
  // const learnosityLib = new LearnosityLibrary();
  // learnosityLib.load();
  // setupShowLearnosityAssesesmentV2({ lib: learnosityLib, ports: app.ports, tts });
  setupComponentPorts(app.ports, platformData, tts);
  setupVideoPlayerPorts(app.ports);
  setupQRCodeReaderPorts(app.ports);
  setupMonitoringPorts(app.ports);
  setupVideoRecordingPorts(app.ports, logger);
  setupWindowManagementPorts(app.ports);
  setupFaviconPorts(app.ports);
  setupScrollingPorts(app.ports);
  setupDeviceCheckPorts(app.ports);
  setupHotjarPorts(app.ports);
  setupSoundPorts(app.ports, logger);
  AppTimerStorage.setupPorts(app.ports);
  setupMediaCapturePermissionsPorts(app.ports);
  setupTeacherTourIframeUpdatePorts(app.ports);
  setupPLEPorts(app.ports);
  setupChoiceTextPorts(app.ports);
  setupGameActivityBrowserHealthPorts(app.ports);
  setupStudentActivityMessageListenerPorts(app.ports);
  stopDoubleTapZoom();
  setupEbookReader(app.ports);
  setupHighfivePorts(app.ports);
  if (platformData.inEsparkIosApp) {
    setupIos(app.ports, platformData);
  }

  app.ports.portsInitialized.send(null);
}

transaction.finish(); // Finishing the transaction will send it to Sentry
